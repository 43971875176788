import React, { useState } from "react";
import "./faq.css";
import { Results, ScrollToTop } from "../../components";
import { faqs } from "../../data";

const FaqPage = () => {
  const [query, setQuery] = useState("");
  const keys = ["title", "details"];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };

  return (
    <>
      <section className="faq">
        <div className="container">
          <div className="faqNav">
            <div className="header">
              <h1>FAQs</h1>
            </div>
            <div className="navList">
              <ul>
                <li>
                  <a href="#general">general</a>{" "}
                </li>
                <li>
                  <a href="#eligibility">eligibility</a>
                </li>
                <li>
                  <a href="#trip">trip</a>
                </li>
                <li>
                  <a href="#safety">safety and security</a>
                </li>
                <li>
                  <a href="#application">application</a>
                </li>
                <li>
                  <a href="#deposit">deposit</a>
                </li>
                <li>
                  <a href="#trip">trip organizers</a>
                </li>
                <li>
                  <a href="#accessibility">accessibility</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="faqContent">
            <div className="searchBar">
              <input
                type="search"
                name="search"
                id="search"
                className="search"
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
              />
            </div>
            <div className="searchResults">
              <Results data={search(faqs)} />
            </div>
          </div>
        </div>
      </section>
      <ScrollToTop />
    </>
  );
};

export default FaqPage;
