import React from "react";
import "./home.css";
import womanStatue from "../../assets/img/woman-with-scales_gradient.png";
import planeGlobe from "../../assets/img/plane-and-globe-2.png";
import founder from "../../assets/img/BANSAH-CEO-LOGO.png";
import CTAButton from "../../ui/CTAButton";
import ServiceCard from "../../components/serviceCard";
import { serviceCardContent } from "../../data";
import whyImage from "../../assets/img/BASAH-COIN-COURT-LAWYERES.png";
import ScrollToTop from "../../components/ScrollToTop";
import { ContactSection } from "../../components";

const Home = () => {
  return (
    <>
      <section className="showcase">
        <div className="hor container">
          <div className="ver">
            <div className="header">
              <h1>
                Simplify your <br />
                immigration process
              </h1>
              <p>
                Expert advice and personalized guidance for a smooth immigration
                journey.
              </p>

              <CTAButton
                link="/booking"
                text="Get started"
                specialClass="blueBkg"
                blank={true}
              />
            </div>
          </div>
          <div className="ver">
            <div className="imgWrapper">
              <img
                className="image"
                src={womanStatue}
                alt="woman with scales gradient"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="aboutSection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="imgWrapper">
                <img className="image" src={planeGlobe} alt="plane and globe" />
              </div>
            </div>
            <div className="ver">
              <div className="txtContent">
                <h2>About Us</h2>
                <p>
                  Welcome to Bansah and Associates: Your Trusted Immigration Law
                  Firm in Ontario, Canada
                </p>
                <p>
                  Bansah & Associates Immigration Services Ltd. was founded on
                  January 30th, 2013 and was first formed in Alberta, Canada.
                  Since then, the company has been a beacon of support for
                  individuals, families, and businesses navigating the intricate
                  landscape of immigration in Canada. Our founder, Mr. Edward
                  Bansah, is a Regulated Canadian Immigration Consultant
                  registered with ICCRC, holding Membership ID R407519. Our
                  office is conveniently located at 207 – 1111 Albion Road,
                  Toronto, ON M9V 1A9, Canada.
                </p>
                <CTAButton
                  link="/booking"
                  text="Get started"
                  specialClass="whiteBkg"
                  blank={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="founder">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <h2 className="gold">Our Founder</h2>
                <p>
                  Mr. Edward Bansah had a notable career at the University of
                  Ghana as an administrator before starting Bansah and
                  Associates. His own immigration story, which began in the
                  United States as a skilled worker and ended in November 1998
                  when he settled in Canada, inspires him to help others fulfill
                  their Canadian aspirations. In addition to his expertise as an
                  interpreter for the Immigration and Refugee Board, Edward has
                  worked for Canadian Tire, a logistics company.
                </p>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <img className="image" src={founder} alt="BANSAH CEO" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="servicesSection">
        <div className="container">
          <div className="header">
            <h2>our services</h2>
          </div>
          <div className="serviceCards">
            <div className="hor">
              {serviceCardContent.map((el) => {
                return (
                  <div className="ver" key={el.id}>
                    <ServiceCard
                      heading={el.heading}
                      body={el.body}
                      url={el.url}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="testimony">
        <div className="container">
          <div className="txtContent">
            <p>
              Bansah & Associates is an exceptional immigration law firm in
              Ontario. Their expertise, professionalism, and dedication helped
              me navigate the complex immigration process smoothly. I am truly
              grateful for their outstanding service and highly recommend them
              to anyone seeking trusted immigration advice.
            </p>
            <div className="testifier">
              <p>- Jane Doe</p>
            </div>
          </div>
        </div>
      </section>
      <section className="whySection">
        <div className="container">
          <div className="hor">
            <div className="ver">
              <div className="txtContent">
                <div className="header">
                  <h2>why choose bansah and associates?</h2>
                </div>
                <div className="bodyContent">
                  <h4>ICCRC Accreditation</h4>
                  <p>
                    Bansah and Associates is proud to be a Regulated Canadian
                    Immigration Consultant registered with ICCRC, ensuring our
                    clients receive advice and representation from qualified
                    professionals.
                  </p>
                </div>
                <div className="bodyContent">
                  <h4>Personalized Service</h4>
                  <p>
                    Our client-centric approach ensures that each client
                    receives individualized attention, working collaboratively
                    to tailor solutions that align with their unique goals and
                    circumstances.
                  </p>
                </div>
                <div className="bodyContent">
                  <h4>Ethical Practices</h4>
                  <p>
                    Integrity remains at the core of our firm. We uphold the
                    highest ethical standards, ensuring transparency and honesty
                    throughout the immigration process.
                  </p>
                </div>
                <div className="bodyContent">
                  <h4>Diverse Expertise</h4>
                  <p>
                    Mr. Edward Bansah's diverse background, from working in the
                    U.S. to his journey in Canada, provides a wealth of
                    experience that sets our firm apart in understanding the
                    challenges and aspirations of our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="ver">
              <div className="imgWrapper">
                <img
                  className="image"
                  src={whyImage}
                  alt="BANSAH COIN COURT LAWYERES"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactSection />

      <ScrollToTop />
    </>
  );
};

export default Home;
