import immigration from "../assets/img/immigration.png";
import residence from "../assets/img/residence-location.png";
import workplace from "../assets/img/workplace.png";
import family from "../assets/img/family.png";
import immigrationlaw from "../assets/img/immigration-law.png";
import iccrc from "../assets/img/iccrc.png";
import personalized from "../assets/img/personalization.png";
import ethical from "../assets/img/skills.png";
import diverse from "../assets/img/multitalented.png";

export const navigationLinks = [
  {
    id: "1",
    name: "About Us",
    url: "/about-us",
  },
  {
    id: "2",
    name: "Our Services",
    url: "/our-services",
  },
  {
    id: "3",
    name: "FAQ",
    url: "/faq",
  },
  {
    id: "4",
    name: "useful links",
    url: "/useful-links",
  },

  {
    id: "5",
    name: "Contact Us",
    url: "/contact-us",
  },
];

export const ourServiceLinks = [
  {
    id: "1",
    name: "immigration consultation",
    url: "/our-services",
  },
  {
    id: "2",
    name: "permanent residency",
    url: "/our-services",
  },
  {
    id: "3",
    name: "work permit and visas",
    url: "/our-services",
  },
  {
    id: "3",
    name: "family sponsorship",
    url: "/our-services",
  },
  {
    id: "3",
    name: "corporate immigration",
    url: "/our-services",
  },
];

export const faqs = [
  {
    id: 1,
    title: "How can I apply?",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro laboriosam quis laudantium nemo sint eligendi, asperiores itaque ut saepe explicabo sunt eius voluptate consectetur veniam sit! Debitis, expedita cupiditate. Doloribus.",
    sectionID: "application",
  },
  {
    id: 2,
    title: "What are the eligibility requirements?",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro laboriosam quis laudantium nemo sint eligendi, asperiores itaque ut saepe explicabo sunt eius voluptate consectetur veniam sit! Debitis, expedita cupiditate. Doloribus.",
    sectionID: "eligibility",
  },
  {
    id: 3,
    title: " What is the duration?",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro laboriosam quis laudantium nemo sint eligendi, asperiores itaque ut saepe explicabo sunt eius voluptate consectetur veniam sit! Debitis, expedita cupiditate. Doloribus.",
    sectionID: "trip",
  },
  {
    id: 4,
    title: "What cultural experiences can I expect?",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro laboriosam quis laudantium nemo sint eligendi, asperiores itaque ut saepe explicabo sunt eius voluptate consectetur veniam sit! Debitis, expedita cupiditate. Doloribus.",
    sectionID: "trip",
  },
  {
    id: 5,
    title: "Safety, security, and health?",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro laboriosam quis laudantium nemo sint eligendi, asperiores itaque ut saepe explicabo sunt eius voluptate consectetur veniam sit! Debitis, expedita cupiditate. Doloribus.",
    sectionID: "safty",
  },
];



export const serviceCardContent = [
  {
    id: "0",
    heading: "immigration consultation",
    body: "Bansah and Associates offers comprehensive immigration consultations, leveraging Mr. Bansah's personal and professional journey to provide unique insights into the Canadian immigration process.",
    url: "/our-services",
    icon: immigration,
  },
  {
    id: "1",
    heading: "permanent residency",
    body: "Our experienced team guides clients through various pathways, including Express Entry, Provincial Nominee Programs (PNPs), and family sponsorship, ensuring a seamless process towards obtaining permanent residency in Canada.",
    url: "/our-services",
    icon: residence,
  },
  {
    id: "2",
    heading: "work permit and visas",
    body: "Navigating work permits and visas is simplified with our expert guidance, ensuring clients have the necessary documentation and support for their employment or travel needs.",
    url: "/our-services",
    icon: workplace,
  },
  {
    id: "3",
    heading: "family sponsorship",
    body: "Reuniting families is a core focus of our practice. We specialize in family sponsorship applications, providing compassionate and effective legal representation.",
    url: "/our-services",
    icon: family,
  },
  {
    id: "4",
    heading: "corporate immigration",
    body: "For businesses looking to establish themselves in Canada, our corporate immigration services offer strategic guidance on work permits, business visas, and compliance with immigration regulations.",
    url: "/our-services",
    icon: immigrationlaw
  },
];

export const whatWeDo = [
  {
    id: "0",
    title: "ICCRC Accreditation",
    desc: "Bansah and Associates is proud to be a Regulated Canadian Immigration Consultant registered with",
    icon: iccrc,
    count: 100
  },
  {
    id: "1",
    title: "Personalized Service",
    desc: "Our client-centric approach ensures that each client receives individualized attention, working",
    icon: personalized,
    count: 100
  },
  {
    id: "2",
    title: "Ethical Practices",
    desc: "Integrity remains at the core of our firm. We uphold the highest ethical standards, ensuring transparency",
    icon: ethical,
    count: 100
  },
  {
    id: "3",
    title: "Diverse Expertise",
    desc: "Mr. Edward Bansah's diverse background, from working in the U.S. to his journey in Canada, provides",
    icon: diverse,
    count: 90
  }
] 
