import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "./../../assets/img/landscape.png";
// import logoBlack from "../../assets/images/NEW_VELTE_composition_trans_BLACK_small.png";
import { FaAlignRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { navigationLinks } from "./../../data";

const Navbar = () => {
  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false);

  const [isNavToggle, setIsNavToggle] = useState(false);
  const toggle = () => setIsNavToggle(!isNavToggle);
  const hide = () => setIsNavToggle(false);
  const show = () => setIsNavToggle(true);

  //logo scroll when active
  const [navbarLogo, setNavbarLogo] = useState(logo);

  //navbar scroll changeBackground function
  const changeBackground = () => {
    console.log(window.scrollY);
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  // const changeLogo = () => {
  //   if (window.scrollY >= 60) {
  //     setNavbarLogo(logoBlack);
  //   } else {
  //     setNavbarLogo(logo);
  //   }
  // };

  // useEffect(() => {
  //   changeLogo();
  //   // adding the event when scroll change Logo
  //   window.addEventListener("scroll", changeLogo);
  // });

  return (
    <nav className={navbar ? "navbar navScroll" : "navbar"}>
      <div className="container">
        <div className="nav__logo">
          <Link to="/">
            <img src={navbarLogo} alt="logo" />
          </Link>
        </div>
        <div
          className={`nav__items ${isNavToggle ? "show__nav" : "hide__nav"}`}
        >
          {navigationLinks.map((links) => {
            return (
              <Link
                className="nav_item"
                key={links.id}
                to={links.url}
                onClick={toggle}
                onBlur={hide}
                onFocus={show}
              >
                {links.name}
              </Link>
            );
          })}
        </div>
        <button id="open__nav-btn" onClick={() => setIsNavToggle(!isNavToggle)}>
          {isNavToggle ? <MdOutlineClose /> : <FaAlignRight />}
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
