import React from "react";
import "./contact.css";
import CTAButton from "../../ui/CTAButton";

const ContactSection = () => {
  return (
    <section className="contactSection">
      <div className="container">
        <div className="txtContent">
          <div className="header">
            <h2>Contact Us</h2>
          </div>
          <p>
            Embark on your immigration journey with confidence. Contact Bansah
            and Associates today to schedule a consultation with our dedicated
            team of immigration experts.
          </p>
          <CTAButton
            link="/booking"
            blank={true}
            text="Get started"
            specialClass="whiteBkg"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
