import React from "react";
import "../../styles.css";
import "./our-services.css";
import { Link } from "react-router-dom";
import immigration from "../../assets/img/immigration.png";
import { serviceCardContent } from "../../data";
import "./flipcard.css";
import { ContactSection, ScrollToTop, WhatWeDoSection } from "../../components";

const OurServices = () => {
  return (
    <>
      {/* heading */}
      <section className="heading">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="mb-4 pt-5 mt-3">OUR SERVICES</h1>

              <h2 className="mb-5">Explore the Diverse Services</h2>

              <p className="mb-0">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Recusandae cupiditate consequuntur deleniti temporibus dolor
                saepe eos quaerat adipisci ducimus animi, autem iusto est
                molestiae rem sapiente excepturi nemo doloremque earum aliquam
                quasi non nam delectus. Libero corrupti consectetur rerum
                deserunt ipsa perferendis quasi ratione alias, aliquid, porro
                non minima reprehenderit quidem sint, at eaque facere nisi quod
                soluta commodi repellat.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* service cards */}
      <section className="service-cards mb-4">
        <div className="container">
          <div className="row card-group d-flex justify-content-center">
            {serviceCardContent.map((item, index) => (
              <div
                key={item.heading + index}
                className="col-lg-4 col-md-6 px-2 mb-3"
              >
                <Link to="#" className="card">
                  <div className="card-inner">
                    <div className="card-front">
                      <div class="card-body">
                        <div className="d-flex justify-content-center align-items-center">
                          <div className="circle">
                            <img
                              src={item.icon}
                              class="card-img"
                              alt="service card"
                            />
                          </div>
                        </div>
                        <div className="title-box">
                          <h4 class="card-title">{item.heading}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-back">
                      <div className="container h-100">
                        <div className="row align-items-center justify-content-center  h-100">
                          <p className="my-auto text-center">{item.body}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <WhatWeDoSection />

      {/* contact */}
      <ContactSection />

      <ScrollToTop />
    </>
  );
};

export default OurServices;
